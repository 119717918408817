

const COLORS = {
    green: '#28A745',
    brand_primary: '#EDCD1E',
    brand_secondary: '#EDCD1E'
}

export const params = function () {
    const URL_PARAMS = {
        apiUri: process.env.REACT_APP_API_URL,
        homePage: process.env.REACT_APP_HOMEPAGE,
        dataUri: process.env.REACT_APP_DATAURI
    };
    return URL_PARAMS;
}

export const colors = function () {
    return COLORS;
}