import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Deploy from "./components/ui/Deploy";



export function App() {

	const ProcessRoutes = () => {
		return (
			<Routes>
				<Route path="/" element={<Deploy />} />
			</Routes>
		)
	}

	return (
		<div>
			<Router>
				<Row className="m-0">
					<ProcessRoutes />
				</Row>
			</Router>
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
		</div>
	);
}