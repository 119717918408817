import { jwtDecode } from "jwt-decode";

export const saveUserData = function (user){
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);
    localStorage.setItem('userAvatar', user.avatar);
    localStorage.setItem('userRole', user.role);
    localStorage.setItem('userId', user.id);
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length < 10){
            logout();
        }else{
            window.location.href = "/inicio"
        }
    }else{
        logout();
    }
}

export const validateToken = function (){
    const token = localStorage.getItem("userToken");

    if(token !== undefined && token !== null){
        const decoded = jwtDecode(token);
        try{
            if(decoded.user.id == null){
                logout();
            }
        }catch(e){
            logout();
        }
    }else{
        logout();
    }    
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const logout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    window.location.href = '/login';
}

export const forceLogout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userSortExpedientes');
    localStorage.removeItem('userOnlyMineExpedientes');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}

export const isLoggedIn = function (){
    return localStorage.getItem('userToken') != null;
}